// $(document).ready(function () {
//     $('[data-toggle="tooltip"]').tooltip();
//     $('[data-toggle="popover"]').popover();
//     $('#content').bootstrapMaterialDesign();
// });

//
// $("#appeal-story").affix({
//     offset: {
//         top: 10,
//         /* affix after top masthead */
//         // top: function () {
//         //     var navOuterHeight = $('#header').height();
//         //     return this.top = navOuterHeight;
//         // },
//         /* un-affix when footer is reached */
//         bottom: function () {
//             return (this.bottom = $('#footer').outerHeight(true))
//         }
//     }
// });
